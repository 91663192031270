//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BackgroundResponsive',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    maxImgWidth() {
      return Math.min(this.mediaQuery?.initialWidth, 1000);
    },
    imgSrc() {
      return `https://cdn3.joinfightcamp.com/${this.maxImgWidth}x0/${this.content.desktop_img_url}`;
    },
  },
  data: () => ({
    loaded: 0,
  }),

  created() {
    this.img_url_full = this.content.default;

    this.pictures = [
      {
        srcset: `https://cdn3.joinfightcamp.com/750x750/filters:format(webp)/${this.content.mobile_img_url} 750w,
                   https://cdn3.joinfightcamp.com/820x820/filters:format(webp)/${this.content.mobile_img_url} 820w,
                   https://cdn3.joinfightcamp.com/1000x1000/filters:format(webp)/${this.content.mobile_img_url} 1000w,
                   https://cdn3.joinfightcamp.com/1526x1526/filters:format(webp)/${this.content.mobile_img_url} 1526w`,

        type: 'image/webp',
        media: '(max-width: 767px)',
      },
      {
        srcset: `https://cdn3.joinfightcamp.com/1000x0/filters:format(webp)/${this.content.desktop_img_url} 1000w,
                   https://cdn3.joinfightcamp.com/1578x0/filters:format(webp)/${this.content.desktop_img_url} 1578w,
                   https://cdn3.joinfightcamp.com/2000x0/filters:format(webp)/${this.content.desktop_img_url} 2000w,
                   https://cdn3.joinfightcamp.com/3000x0/filters:format(webp)/${this.content.desktop_img_url} `,
        type: 'image/webp',
      },
      {
        srcset: `https://cdn3.joinfightcamp.com/750x750/${this.content.mobile_img_url} 750w,
                   https://cdn3.joinfightcamp.com/820x820/${this.content.mobile_img_url} 820w,
                   https://cdn3.joinfightcamp.com/1000x1000/${this.content.mobile_img_url} 1000w,
                   https://cdn3.joinfightcamp.com/1526x1526/${this.content.mobile_img_url} 1526w`,

        type: 'image/jpeg',
        media: '(max-width: 767px)',
      },
      {
        srcset: `https://cdn3.joinfightcamp.com/1000x0/${this.content.desktop_img_url} 1000w,
                   https://cdn3.joinfightcamp.com/1578x0/${this.content.desktop_img_url} 1578w,
                   https://cdn3.joinfightcamp.com/2000x0/${this.content.desktop_img_url} 2000w,
                   https://cdn3.joinfightcamp.com/3000x0/${this.content.desktop_img_url} `,
        type: 'image/jpeg',
      },
    ];
  },
};
